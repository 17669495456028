import request from "@/utils/request";

// 生成或重置用户APP key信息
export const appKey_generate_reset = () => {
	return request({
		url: "/client_v1/user/generate-reset",
		method: "POST",
	});
};

// 用户appID信息
export const appID_info = () => {
	return request({
		url: "/client_v1/user/get-appkey",
		method: "POST",
	});
};
