<template>
	<div class="ApiPage">
		<div class="menu">
			<a-menu
				style="width: 210px; height: 100%"
				mode="inline"
				:default-selected-keys="['1']"
				:selected-keys="[current]">
				<a-menu-item key="ApiPage" class="menu_one">
					<div class="my_equipment"></div>
					<div class="title">API</div>
				</a-menu-item>
			</a-menu>
		</div>
		<div class="info">
			<section class="section1">
				<h2>用户凭证</h2>
				<div class="block">
					<div class="line">
						<div class="title">api_id：</div>
						<div class="content">
							{{
								user_appID_info.app_id ||
								"点击下方按钮一键生成APP ID"
							}}
						</div>
						<div class="other"></div>
					</div>
					<div class="line">
						<div class="title">api_key：</div>
						<div class="content">
							{{
								user_appID_info.app_secret ||
								"点击下方按钮一键生成APP Secret"
							}}
						</div>
						<div class="other">
							<template
								v-if="
									user_appID_info.app_id &&
									user_appID_info.app_secret
								">
								<div class="button" @click="operate(1)">
									<img
										width="16"
										height="16"
										src="@/assets/img/icon_copy.png"
										alt="" />
									<span>复制</span>
								</div>
								<div class="button" @click="operate(2)">
									<img
										width="16"
										height="16"
										src="@/assets/img/icon_reset.png"
										alt="" />
									<span>重置</span>
								</div>
							</template>
						</div>
					</div>
					<div class="line">
						<div class="title">接口文档</div>
						<div class="content">
							<img
								width="20"
								height="20"
								src="@/assets/img/icon_doc.png"
								alt="" />
							<a
								href="https://www.yangtao.com/help/apidoc"
								target="new"
								>API接口文档</a
							>
						</div>
						<div class="other"></div>
					</div>
					<div class="line">
						<div class="title"></div>
						<div class="content">
							<a-button
								type="primary"
								@click="generate"
								:loading="generateLoading"
								v-if="
									!user_appID_info.app_id &&
									!user_appID_info.app_secret
								"
								>一键生成</a-button
							>
						</div>
						<div class="other"></div>
					</div>
				</div>
			</section>
			<section class="section2">
				<h2>使用须知</h2>
				<p>1. 使用前准备：</p>
				<p>- 检查登录的账号是否拥有API的功能权限。</p>
				<p>- 检查接口状态是否正常。</p>
				<p>2. 参数说明:</p>
				<p>- POST的内容均为JSON格式。</p>
				<p>- 非必填参数均为可选参数，可以不传递。</p>
				<p>3. 接口上限：</p>
				<p>- 访问频次，所有接口每秒最多请求1次。</p>
			</section>
		</div>
		<a-modal
			title="提示"
			:visible="visible"
			:confirm-loading="confirmLoading"
			@ok="handleOk"
			@cancel="handleCancel">
			<p class="text">确定重置APP ID 和APP Secret吗？</p>
			<p class="error">
				重置APP ID和APP Secret，旧值将不可用，请谨慎操作
			</p>
		</a-modal>
	</div>
</template>

<script>
import { appKey_generate_reset, appID_info } from "@/api/app.js";
import { copy } from "iclipboard";
export default {
	components: {},
	name: "ApiPage",
	data() {
		return {
			current: "ApiPage",
			user_appID_info: {},
			visible: false,
			confirmLoading: false,
			generateLoading: false,
		};
	},
	created() {
		appID_info().then(res => {
			this.user_appID_info = res.data.data;
		});
	},
	methods: {
		operate(type) {
			if (type === 1) {
				if (copy(this.user_appID_info.app_secret)) {
					this.$message.success("复制成功");
				} else {
					this.$message.error("复制失败，请手动复制");
				}
			}
			if (type === 2) {
				this.visible = true;
			}
		},
		resetAppKey() {},
		handleOk() {
			this.confirmLoading = true;
			appKey_generate_reset().then(res => {
				if (res.data.code == 200) {
					this.confirmLoading = false;
					this.visible = false;
					this.$message.success("重置成功");
					this.user_appID_info = res.data.data;
				}
			});
		},
		handleCancel() {
			this.visible = false;
		},
		generate() {
			this.generateLoading = true;
			appKey_generate_reset().then(res => {
				if (res.data.code == 200) {
					this.generateLoading = false;
					this.user_appID_info = res.data.data;
				}
			});
		},
	},
};
</script>
<style lang="less" scoped>
.ApiPage {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	padding-left: 15px;
	.menu {
		width: 210px;
		background-color: #fff;
		padding-top: 38px;
		.menu_one {
			display: flex;
			flex-direction: row;
			height: 46px;
			.ant-menu-item-selected {
				background: linear-gradient(90deg, #f7f9ff 0%, #ebf0fc 100%);
			}
			.my_equipment {
				margin-top: 15px;
				margin-right: 7px;
				width: 18px;
				height: 18px;
				background-image: url("../../../assets/img/icon_api.png");
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}
			.will_expire {
				background-image: url("../../../assets/img/登录请求授权@2x.png");
			}
			.expired {
				background-image: url("../../../assets/img/终端@2x.png");
			}
			.title {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374567;
				line-height: 46px;
			}
			.count {
				border: 1px solid #374567;
			}
		}
	}
	.info {
		flex: 1;
		height: calc(100vh - 90px);
		background-color: #fff;
		margin-left: 11px;
		padding: 40px;
		box-sizing: border-box;
		section {
			h2 {
				font-size: 18px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2c354b;
				line-height: 25px;
				padding-left: 11px;
				position: relative;
				&::before {
					content: "";
					display: block;
					position: absolute;
					width: 3px;
					height: 17px;
					top: 4px;
					background-color: #4c84ff;
					left: 0;
				}
			}
			&.section1 {
				.block {
					width: 762px;
					background: #f9faff;
					border-radius: 4px;
					margin-top: 16px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					box-sizing: border-box;
					padding: 16px 0;
					gap: 12px;
					.line {
						display: flex;
						align-items: center;
						.title {
							flex: 0 0 116px;
							text-align: right;
						}
						.content {
							flex: 0 0 400px;
							margin: 0 40px;
							display: flex;
							align-items: center;
							a {
								margin-left: 6px;
								text-decoration: underline;
							}
						}
						.other {
							display: flex;
							align-items: center;
							.button {
								width: 62px;
								height: 26px;
								border-radius: 4px;
								display: flex;
								align-items: center;
								justify-content: center;
								cursor: pointer;
								&:nth-child(1) {
									background: #f8faff;
									border: 1px solid #4c84ff;
								}
								&:nth-child(2) {
									background: #fff9f1;
									border: 1px solid #ffad0b;
									margin-left: 12px;
								}
							}
						}
					}
				}
			}
			&.section2 {
				margin-top: 40px;
				h2 {
					margin-bottom: 16px;
				}
				p {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #878fa7;
					line-height: 22px;
				}
			}
		}
	}
}
::v-deep .ant-modal {
	p {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 22px;
		&.error {
			color: #ff4d50;
		}
	}
}
</style>
