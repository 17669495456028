var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ApiPage"},[_c('div',{staticClass:"menu"},[_c('a-menu',{staticStyle:{"width":"210px","height":"100%"},attrs:{"mode":"inline","default-selected-keys":['1'],"selected-keys":[_vm.current]}},[_c('a-menu-item',{key:"ApiPage",staticClass:"menu_one"},[_c('div',{staticClass:"my_equipment"}),_c('div',{staticClass:"title"},[_vm._v("API")])])],1)],1),_c('div',{staticClass:"info"},[_c('section',{staticClass:"section1"},[_c('h2',[_vm._v("用户凭证")]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"title"},[_vm._v("api_id：")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.user_appID_info.app_id || "点击下方按钮一键生成APP ID")+" ")]),_c('div',{staticClass:"other"})]),_c('div',{staticClass:"line"},[_c('div',{staticClass:"title"},[_vm._v("api_key：")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.user_appID_info.app_secret || "点击下方按钮一键生成APP Secret")+" ")]),_c('div',{staticClass:"other"},[(
								_vm.user_appID_info.app_id &&
								_vm.user_appID_info.app_secret
							)?[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.operate(1)}}},[_c('img',{attrs:{"width":"16","height":"16","src":require("@/assets/img/icon_copy.png"),"alt":""}}),_c('span',[_vm._v("复制")])]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.operate(2)}}},[_c('img',{attrs:{"width":"16","height":"16","src":require("@/assets/img/icon_reset.png"),"alt":""}}),_c('span',[_vm._v("重置")])])]:_vm._e()],2)]),_vm._m(0),_c('div',{staticClass:"line"},[_c('div',{staticClass:"title"}),_c('div',{staticClass:"content"},[(
								!_vm.user_appID_info.app_id &&
								!_vm.user_appID_info.app_secret
							)?_c('a-button',{attrs:{"type":"primary","loading":_vm.generateLoading},on:{"click":_vm.generate}},[_vm._v("一键生成")]):_vm._e()],1),_c('div',{staticClass:"other"})])])]),_vm._m(1)]),_c('a-modal',{attrs:{"title":"提示","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('p',{staticClass:"text"},[_vm._v("确定重置APP ID 和APP Secret吗？")]),_c('p',{staticClass:"error"},[_vm._v(" 重置APP ID和APP Secret，旧值将不可用，请谨慎操作 ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"title"},[_vm._v("接口文档")]),_c('div',{staticClass:"content"},[_c('img',{attrs:{"width":"20","height":"20","src":require("@/assets/img/icon_doc.png"),"alt":""}}),_c('a',{attrs:{"href":"https://www.yangtao.com/help/apidoc","target":"new"}},[_vm._v("API接口文档")])]),_c('div',{staticClass:"other"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section2"},[_c('h2',[_vm._v("使用须知")]),_c('p',[_vm._v("1. 使用前准备：")]),_c('p',[_vm._v("- 检查登录的账号是否拥有API的功能权限。")]),_c('p',[_vm._v("- 检查接口状态是否正常。")]),_c('p',[_vm._v("2. 参数说明:")]),_c('p',[_vm._v("- POST的内容均为JSON格式。")]),_c('p',[_vm._v("- 非必填参数均为可选参数，可以不传递。")]),_c('p',[_vm._v("3. 接口上限：")]),_c('p',[_vm._v("- 访问频次，所有接口每秒最多请求1次。")])])
}]

export { render, staticRenderFns }